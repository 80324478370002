import React from "react";
import { key2pos } from "../chessUtils/util";
import { getPosFromKey } from "../chessUtils/util";
import styles from "./DrawLayer.module.css";
import pieceStyles from "./Piece.module.css";
import { COLOR_LETTERS, PIECE_LETTERS } from "../chessUtils/fen";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import PuzzleBadge from "./PuzzleBadge";
import AnalysBadge from "./AnalysBadge";
import ChessSet from "@/src/modules/settings/ChessSet";
import { ChessSets } from "@chesshotel/constants";

function renderDot(pos, i) {
  const x1 = pos[0] * 12.5 + 12.5 / 2 + "%";
  const y1 = pos[1] * 12.5 + 12.5 / 2 + "%";

  return (
    <circle
      key={i}
      fill="#333333"
      opacity={0.15}
      cx={x1}
      cy={y1}
      r={"2%"}
    ></circle>
  );
}

// function renderRect(pos, i) {
//   const x1 = pos[0] * 12.5 + 0.3 + "%";
//   const y1 = pos[1] * 12.5 + 0.3 + "%";

//   return <use href="#rect" key={i} x={x1} y={y1} />;
// }

function renderCircle(pos, i) {
  const x1 = pos[0] * 12.5 + 12.5 / 2 + "%";
  const y1 = pos[1] * 12.5 + 12.5 / 2 + "%";

  return (
    <circle
      key={i}
      stroke="#333333"
      fill="none"
      strokeWidth="1.1%"
      opacity={0.15}
      cx={x1}
      cy={y1}
      r={"5.2%"}
    ></circle>
  );
}

function renderMoveHelp(moveHelp, playingWhite, position) {
  const shapes = [];
  let lastKey = null;
  for (let i = 0; i < moveHelp.length; i++) {
    const key = moveHelp[i];
    if (key != lastKey) {
      lastKey = key;
      const pos = getPosFromKey(key, playingWhite);
      if (
        position[key] &&
        ((position[key].color == "white" && !playingWhite) ||
          (position[key].color == "black" && playingWhite))
      ) {
        shapes.push(renderCircle(pos, i));
      } else {
        shapes.push(renderDot(pos, i));
      }
    }
  }

  return (
    <div className="absolute z-10 w-full h-full">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        {shapes}
      </svg>
    </div>
  );
}

function renderLastMove(lastMove, lastMoveColor, orangeEnabled, chessSet) {
  const fromPos = key2pos(lastMove.from);
  const toPos = key2pos(lastMove.to);

  const squareClassFrom = `square-${fromPos[0]}${fromPos[1]}`;
  const squareClassTo = `square-${toPos[0]}${toPos[1]}`;
  const colorClass = lastMoveColor ? " " + styles[lastMoveColor] : "";

  let defaultColor = orangeEnabled
    ? styles.lastMoveOrange
    : styles.lastMoveYellow;

  if (chessSet == ChessSets.STAUNTY) {
    defaultColor = styles.lastMoveForStaunty;
  }

  return (
    <div className="relative flex w-full h-full overflow-hidden md:rounded">
      <div
        className={`${defaultColor} ${pieceStyles[squareClassFrom]}${colorClass}`}
      />
      <div
        className={`${defaultColor} ${pieceStyles[squareClassTo]}${colorClass}`}
      />
    </div>
  );
}

function renderHighlight(highLightKey) {
  const highLightPos = highLightKey && key2pos(highLightKey);
  const squareClass = `square-${highLightPos[0]}${highLightPos[1]}`;
  return (
    <div className={`${styles.highlight} ${pieceStyles[squareClass]}`}></div>
  );
}

function renderMoveSquare(moveSquarePos, orangeEnabled, chessSet) {
  const squareClass = `square-${moveSquarePos[0]}${moveSquarePos[1]}`;

  let squareOverlay = orangeEnabled
    ? styles.squareOverlayOrange
    : styles.squareOverlayYellow;

  // if (chessSet == ChessSets.STAUNTY) {
  //   squareOverlay = styles.squareOverlayForStaunty;
  // }

  return <div className={`${pieceStyles[squareClass]} ${squareOverlay}`}></div>;
}

export function renderCompletePuzzle(lastPuzzleMove) {
  const toPos = lastPuzzleMove && key2pos(lastPuzzleMove.to);
  const squareClass = `square-${toPos[0]}${toPos[1]}`;

  return (
    <div
      className={`z-10 w-[12.5%] absolute h-[12.5%] ${pieceStyles[squareClass]}`}
    >
      <PuzzleBadge />
    </div>
  );
}

function renderGoast(goast, chessSet) {
  const {
    goastPiece: { color, role },

    goastPos,
  } = goast;

  // const pieceClass =
  //   pieceStyles[goast.goastPiece.color + goast.goastPiece.role];

  const pieceFile = COLOR_LETTERS[color] + PIECE_LETTERS[role] + ".svg";

  const squareClass = pieceStyles[`square-${goastPos[0]}${goastPos[1]}`];

  return (
    <div
      style={{
        backgroundImage: `url(/images/pieces/${chessSet}/${pieceFile})`,
      }}
      className={`${pieceStyles.goast} ${pieceStyles.piece} ${squareClass}`}
    ></div>
  );
}

function renderAnalys(pos, color) {
  if (!pos) {
    return <></>;
  }
  const toPos = pos && key2pos(pos.to);
  const squareClass = `square-${toPos[0]}${toPos[1]}`;

  return (
    <div
      className={`z-50 w-[12.5%] absolute h-[12.5%] ${pieceStyles[squareClass]}`}
    >
      <AnalysBadge moveColor={color} />
    </div>
  );
}

function DrawLayer({
  highLightKey,
  puzzleDone,
  playingWhite,
  goast,
  moveSquarePos,
  moveHelp,
  position,
  showLastMove,
  lastMove,
  lastMoveColor,
  settings,
  lastPuzzleMove,
}) {
  const orangeEnabled = useFeatureIsOn("orange-last-move");
  const analysisBadge = useFeatureIsOn("analysisbadges");

  return (
    <>
      {lastMove &&
        (settings.lastMove || showLastMove) &&
        renderLastMove(
          lastMove,
          lastMoveColor,
          orangeEnabled,
          settings.chessSet
        )}
      {moveHelp && renderMoveHelp(moveHelp, playingWhite, position)}
      {moveSquarePos &&
        renderMoveSquare(moveSquarePos, orangeEnabled, settings.chessSet)}
      {highLightKey && renderHighlight(highLightKey)}
      {goast && renderGoast(goast, settings.chessSet)}
      {puzzleDone && renderCompletePuzzle(lastPuzzleMove)}
      {analysisBadge && lastMoveColor && renderAnalys(lastMove, lastMoveColor)}
    </>
  );
}

export default DrawLayer;
