import React from "react";
import { getPosFromKey } from "../chessUtils/util";

function renderArrow(fromPos, toPos, i = null) {
  const m = 1;
  const dx = toPos[0] - fromPos[0];
  const dy = toPos[1] - fromPos[1];
  const angle = Math.atan2(dy, dx);
  const xr = Math.cos(angle);
  const yr = Math.sin(angle);
  const xe = xr * m;
  const ye = yr * m;

  const xs = 0; // xr * 6.25;
  const ys = 0; // yr * 6.25;

  const x1 = fromPos[0] * 12.5 + 12.5 / 2 + xs + "%";
  const y1 = fromPos[1] * 12.5 + 12.5 / 2 + ys + "%";

  const x2 = toPos[0] * 12.5 + 12.5 / 2 - xe + "%";
  const y2 = toPos[1] * 12.5 + 12.5 / 2 - ye + "%";

  return (
    <line
      key={i}
      stroke="#333333"
      strokeWidth="2%"
      strokeLinecap="round"
      markerEnd="url(#arrowhead)"
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
    ></line>
  );
}

function renderCircle(fromPos, i) {
  const x1 = fromPos[0] * 12.5 + 12.5 / 2 + "%";
  const y1 = fromPos[1] * 12.5 + 12.5 / 2 + "%";

  return (
    <circle
      key={i}
      stroke="#333333"
      fill="none"
      strokeWidth="2%"
      cx={x1}
      cy={y1}
      r={"5%"}
    ></circle>
  );
}

function renderShapes(shapes) {
  return shapes.map((shape, i) => {
    if (
      shape.to == null ||
      (shape.to[0] == shape.from[0] && shape.to[1] == shape.from[1])
    ) {
      return renderCircle(shape.from, i);
    } else if (
      shape.from != null &&
      shape.to != null &&
      shape.from != shape.to
    ) {
      return renderArrow(shape.from, shape.to, i);
    }
  });
}

function renderBestMove(bestMove, playingWhite) {
  const posFrom = getPosFromKey(bestMove.from, playingWhite);
  const posTo = getPosFromKey(bestMove.to, playingWhite);

  return renderArrow(posFrom, posTo);
}

export default function SvgLayer({ shapes = null, bestMove, playingWhite }) {
  return (
    <div className="w-full h-full absolute z-10">
      <svg width="100%" height="100%" opacity="0.3">
        <defs>
          <marker
            id="arrowhead"
            orient="auto"
            markerWidth={"6.25%"}
            markerHeight={"3.125%"}
            refX="2.1"
            refY="2.0"
          >
            <path d="M0,0 V4 L3,2 Z" fill="#333333"></path>
          </marker>
        </defs>
        {bestMove && renderBestMove(bestMove, playingWhite)}
        {shapes && renderShapes(shapes)}
      </svg>
    </div>
  );
}
