import React from "react";
//text-[#7b6b5b]
export default function NumbersLetters({ playingWhite }) {
  return (
    <>
      <div
        className="absolute z-[1] top-0 left-0 h-full pt-0.5 
      md:py-5 w-2 md:w-5 flex justify-center md:text-altgray-500"
      >
        <div
          className={`flex [&>*]:h-[12.5%] md:[&>*]:h-auto md:justify-around
          text-[0.5rem] md:text-[0.65rem] font-semibold 
             ${playingWhite ? "flex-col" : "flex-col-reverse"}`}
        >
          <div>8</div>
          <div>7</div>
          <div>6</div>
          <div>5</div>
          <div>4</div>
          <div>3</div>
          <div>2</div>
          <div>1</div>
        </div>
      </div>
      <div
        className="absolute z-[1] bottom-0 -mb-[1px] left-0 pb-0 
      md:pb-0 h-3.5 md:h-5 w-full flex overflow-hidden items-center md:text-altgray-500"
      >
        <div
          className={`w-full flex translate-x-[calc(12.5%-8px)] md:translate-x-0 
    [&>*]:w-[12.5%] md:[&>*]:w-auto md:justify-around text-[0.5rem] md:text-[0.65rem] font-semibold md:px-5
    ${playingWhite ? "flex-row" : "flex-row-reverse"}
    `}
        >
          <div>A</div>
          <div>B</div>
          <div>C</div>
          <div>D</div>
          <div>E</div>
          <div>F</div>
          <div>G</div>
          <div>H</div>
        </div>
      </div>
    </>
  );
}
