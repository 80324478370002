import { FaCheck } from "@react-icons/all-files/fa/FaCheck";
import styles from "./DrawLayer.module.css";
import {
  Cross1Icon,
  QuestionMarkIcon,
  StarFilledIcon,
} from "@radix-ui/react-icons";

const AnalysBadge = ({ moveColor }) => {
  const colorClass = moveColor ? " " + styles[moveColor + "Ana"] : "";

  return (
    <div className="z-50">
      {moveColor == "blueSquare" ? (
        <FaCheck
          className={` text-white p-1 ${colorClass}   rounded-full  w-[50%] absolute  -right-3 -top-3 h-[50%]`}
        />
      ) : moveColor == "purpleSqare" ? (
        <QuestionMarkIcon
          className={` text-white p-1 ${colorClass}   rounded-full  w-[50%] absolute  -right-3 -top-3 h-[50%]`}
        />
      ) : moveColor == "greenSquare" ? (
        <StarFilledIcon
          className={` text-white p-1 ${colorClass}   rounded-full  w-[50%] absolute  -right-3 -top-3 h-[50%]`}
        />
      ) : moveColor == "redSquare" ? (
        <Cross1Icon
          className={` text-white p-1 ${colorClass}   rounded-full  w-[50%] absolute  -right-3 -top-3 h-[50%]`}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AnalysBadge;
