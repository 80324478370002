import React from "react";

function PromotionPiece({ style, onClick }) {
  return (
    <div
      className="w-[12.5%] h-[12.5%] z-20 will-change-transform
    select-none cursor-pointer m-[2%] hover:opacity-70 active:opacity-50 bg-cover"
      style={style}
      onClick={onClick}
    ></div>
  );
}

export default function Promotion({ playingWhite, makePromotion, settings }) {
  const color = playingWhite ? "white" : "black";
  const colorLetter = playingWhite ? "w" : "b";
  const { chessSet } = settings;

  return (
    <div
      className="absolute z-50 flex items-center justify-center w-full h-full 
      bg-center bg-no-repeat bg-[size:80%]"
      style={{
        backgroundImage: "url(/images/promotion-bg.png)",
      }}
    >
      <PromotionPiece
        style={{
          backgroundImage: `url(/images/pieces/${chessSet}/${colorLetter}Q.svg)`,
        }}
        onClick={() => makePromotion("queen", "q", color)}
      />
      <PromotionPiece
        style={{
          backgroundImage: `url(/images/pieces/${chessSet}/${colorLetter}R.svg)`,
        }}
        onClick={() => makePromotion("rook", "r", color)}
      />
      <PromotionPiece
        style={{
          backgroundImage: `url(/images/pieces/${chessSet}/${colorLetter}B.svg)`,
        }}
        onClick={() => makePromotion("bishop", "b", color)}
      />
      <PromotionPiece
        style={{
          backgroundImage: `url(/images/pieces/${chessSet}/${colorLetter}N.svg)`,
        }}
        onClick={() => makePromotion("knight", "n", color)}
      />
    </div>
  );
}
