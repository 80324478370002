import { FaCheck } from "@react-icons/all-files/fa/FaCheck";

const PuzzleBadge = () => {
  return (
    <div className=" z-50">
      <FaCheck className=" text-white p-1 bg-[#324456] rounded-full  w-[50%] absolute  -right-2 -top-2 h-[50%]" />
    </div>
  );
};

export default PuzzleBadge;
